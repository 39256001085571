<template>
  <PageTitleTop>
    <template v-slot:title>商家认证</template>
    <Row justify="end" class="btn-box">
      <Button
        v-if="!companyInfo.id"
        class="commit-btn"
        @click="reauthentication"
      >
        编辑认证信息
      </Button>
    </Row>

    <div v-if="status === 1" class="main-content content-info">
      <img :src="require('/static/icons/person.png')" />
      <span>
        恭喜您注册成功！点击到
        <router-link to="/identify" style="color: #57a3f3">
          认证完善
        </router-link>
        ，补全您的资质
      </span>
    </div>
    <div v-if="status === 2" class="main-content content-info">
      <img :src="require('/static/icons/warn.png')" alt="" />
      <span class="secondary-info">认证信息已提交，正在审核中</span>
      <span>
        您的资料已提交成功，我们将会在2-5个工作日与您电话联系，请您保持电话畅通，谢谢。
      </span>
    </div>
    <div v-if="status === 3" class="main-content content-info">
      <img :src="require('/static/icons/error.png')" alt="" />
      <span class="secondary-info">审核未通过</span>
      <span>您的资料审核未通过，请核实后重新认证</span>
      <router-link to="" @click="reauthentication">重新认证</router-link>
    </div>

    <div v-if="status >= 4" class="main-content">
      <Form ref="formValidate" :label-width="200">
        <PageSection>
          <template v-slot:title>公司信息</template>
          <FormItem label="企业名称">
            {{ companyInfo.shortName }}
          </FormItem>
          <FormItem label="营业执照注册号">
            {{ companyInfo.taxNo }}
          </FormItem>
          <FormItem label="公司所在地">
            {{ companyInfo.currentAddress }}
          </FormItem>
          <FormItem label="公司紧急联系人">
            {{ companyInfo.emergencyContactPerson }}
          </FormItem>
          <FormItem label="公司紧急联系人电话">
            {{ companyInfo.emergencyTel }}
          </FormItem>
        </PageSection>
        <PageSection>
          <template v-slot:title>法人信息</template>
          <FormItem label="法人代表证件类型">
            <div v-if="companyInfo.legalPersonCardType === 1">身份证</div>
            <!-- {{ companyInfo.legalPersonCardType }} -->
          </FormItem>
          <FormItem label="法人代表证件号">
            {{ companyInfo.legalPersonCardId }}
          </FormItem>
          <FormItem label="法人代表名称">
            {{ companyInfo.legalPersonName }}
          </FormItem>
          <FormItem label="法人代表联系方式">
            {{ companyInfo.legalPersonTel }}
          </FormItem>
        </PageSection>
        <PageSection>
          <template v-slot:title>法人信息</template>
          <FormItem label="营业执照/三证合一电子版">
            <div class="img">
							<img :src="'data:image/png/jpg/jpeg;base64,'+companyInfo.businessLicense" >
              <!-- <img :src="companyInfo.businessLicense" alt="" /> -->
            </div>
          </FormItem>
          <FormItem label="法人证件电子版">
            <div class="img">
							<img :src=" 'data:image/png;base64,' + companyInfo.legalPersonCardFrontUrl "/>
              <!-- <img :src="companyInfo.legalPersonCardFrontUrl" alt="" /> -->
            </div>
            <div class="img">
							<img :src=" 'data:image/png;base64,' + companyInfo.legalPersonCardBackUrl "/>
              <!-- <img :src="companyInfo.legalPersonCardBackUrl" alt="" /> -->
            </div>
          </FormItem>
        </PageSection>
      </Form>
    </div>
  </PageTitleTop>
</template>
<script>
import { getCompanyInfo } from '@/api/user.js'
export default {
  data () {
    return {
      status: 0,
      companyInfo: {},
      companyId: '',
    }
  },
  created () {
    this.status = this.$store.state.login.userInfo.status
    this.companyId = this.$store.state.login.userInfo.companyId
    if (!this.isFaild) {
      this.getCommitedInfo()
    }
  },
  methods: {
    reauthentication () {
      this.$router.push('/identify')
    },
    /* 获取之前提交的数据 */
    getCommitedInfo () {
      const params = {
        companyId: this.companyId,
      }
      getCompanyInfo(params).then((res) => {
        if (res.returnCode === '1') {
          this.companyInfo = res.result
        }
      })
    },
  },
}
</script>
<style lang="less">
.main-content {
  margin-top: 40px;
}
.content-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;
  span,
  a {
    height: 40px;
    line-height: 40px;
  }
  a {
    margin-top: 20px;
    color: #58aaff;
  }
  img {
    width: 60px;
    height: 60px;
  }
}
.btn-box {
  position: absolute;
  right: 0;
  top: 20px;
}
.commit-btn {
  width: auto;
}
.img {
  width: 120px;
  height: auto;
  margin-top: 20px;
}
</style>
